
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import miniSidebar from '@/store/modules/miniSidebar'
import MessageContactTitle from './MessageContactTitle.vue'
import MessageContactSearch from './MessageContactSearch.vue'
import { TwilioContact, TwilioMessage } from '@/models/dto/Twilio'
import { tempMessageHistory } from '@/data/temp'
import dayjs from 'dayjs'
import auth from '@/store/modules/auth'
import { v4 } from 'uuid'
import colors from '@/scss/_colors-export.scss'

@Component({
  components: {
    MessageContactTitle,
    MessageContactSearch,
  },
})
export default class MessageMessagesPanel extends mixins(DateMixin) {
  @Prop({ required: true, default: null }) contact: TwilioContact
  @Prop({ required: true, default: () => [] }) messageHistory: TwilioMessage[]

  colors = colors
  loading = false
  scroll = null

  @Watch('displayMessages')
  handleDisplayMessagesUpdate(): void {
    this.$nextTick(() => {
      this.scrollToBottom()
    })
  }

  get displayMessages(): TwilioMessage[] {
    return this.messageHistory.reduce((arr, message) => {
      const idx = tempMessageHistory.indexOf(message)
      if (
        idx === 0 ||
        dayjs(tempMessageHistory[idx - 1].dateSent).format('YYYY-MM-DD') !==
          dayjs(message.dateSent).format('YYYY-MM-DD')
      ) {
        let dateSent = dayjs(message.dateSent)
          .tz(auth.getUserTimeZone)
          .format('YYYY-MM-DD')
        if (dayjs().diff(dateSent, 'day') === 0) {
          dateSent = 'Today'
        } else if (dayjs().diff(dateSent, 'day') === 1) {
          dateSent = 'Yesterday'
        } else {
          dateSent = this.formatMonthDay(dateSent)
        }
        arr.push(
          new TwilioMessage({
            sid: v4(),
            dateSent,
          })
        )
      }
      arr.push({
        ...message,
        dateSent: this.formatShortTime(message.dateSent, {
          tz: auth.getUserTimeZone,
          showMeridianLower: true,
        }),
      })
      return arr
    }, [])
  }

  scrollToBottom(): void {
    this.scroll = this.$refs['message-area-wrapper']['clientHeight']
  }
}
