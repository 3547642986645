
import { Component, Vue, Watch } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import miniSidebar from '@/store/modules/miniSidebar'
import deepClone from '@/utils/deepClone'
import MessageContactTitle from './MessageContactTitle.vue'
import MessageContactPanel from './MessageContactPanel.vue'
import { TwilioContact, TwilioMessage } from '@/models/dto/Twilio'
import { phoneFormatFilter } from '@/utils/string'
import { tempMessageContacts } from '@/data/temp'

@Component({
  components: {
    MessageContactTitle,
  },
})
export default class TheMessagesPanel extends Vue {
  transitioning = false
  debounce: ReturnType<typeof setTimeout> | null = null
  searchMessagesQuery = ''
  tempData: TwilioContact[] = []
  displayData: TwilioContact[] = []

  @Watch('searchMessagesQuery')
  handleUpdateSearchQuery(searchMessagesQuery: string): void {
    if (this.debounce) {
      clearTimeout(this.debounce)
    }

    this.debounce = setTimeout(() => {
      const data = deepClone(this.tempData)
      const query = searchMessagesQuery.toLowerCase()
      this.displayData = data
        .map((el) => ({
          ...el,
          number: phoneFormatFilter(el.number?.toString()),
        }))
        .reduce((arr, el) => {
          if (!query) {
            arr.push(el)
            return arr
          }
          const newMessage = { ...el }
          let flag = false
          if ((el.name || '').toLowerCase().includes(query)) {
            newMessage.name = this.highlightQuery(query, el.name)
            flag = true
          }
          if ((el.customerAccount || '').toLowerCase().includes(query)) {
            newMessage.customerAccount = this.highlightQuery(query, el.customerAccount)
            flag = true
          }
          if ((el.lastMessage || '').toLowerCase().includes(query)) {
            newMessage.lastMessage = this.highlightQuery(query, el.lastMessage)
            flag = true
          }
          if ((el.number || '').toLowerCase().includes(query)) {
            newMessage.number = this.highlightQuery(query, el.number)
            flag = true
          }
          if (flag) {
            arr.push(newMessage)
          }
          return arr
        }, [])
    }, 250)
  }

  highlightQuery(query: string, message: string): string {
    const startIndex = message.toLowerCase().indexOf(query)
    const endIndex = startIndex + query.length
    const match = message.slice(startIndex, endIndex)
    const before = message.slice(0, startIndex)
    const after = message.slice(endIndex)
    return `${before}<mark>${match}</mark>${after}`
  }

  handleOpenMessageContactPanel(contact: TwilioContact = null): void {
    miniSidebar.popAllAndPush({
      component: MessageContactPanel,
      props: {
        contacts: this.tempData,
        inputContact: contact,
      },
    })
  }

  close(): void {
    sidebar.pop()
  }

  mounted(): void {
    this.tempData = tempMessageContacts

    this.displayData = deepClone(
      this.tempData.map((el) => ({
        ...el,
        number: phoneFormatFilter(el.number?.toString()),
      }))
    )
  }
}
