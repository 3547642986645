
import { Component, Vue } from 'vue-property-decorator'
import BusifyLogo from '@/components/BusifyLogo.vue'
import { getNavigationLinks } from '@/data/navigation'
import { NavigationLink } from '@/models/NavigationLink'
import modules from '@/store/modules'
import auth from '@/store/modules/auth'
import CUAvatar from '@/components/CUAvatar.vue'
import { EventBus } from '@/utils/eventBus'
import impersonation from '@/store/modules/impersonation'
import { getId } from '@/utils/id'
import colors from '@/scss/_colors-export.scss'
import app from '@/store/modules/app'

@Component({
  components: {
    CUAvatar,
    BusifyLogo,
  },
})
export default class TheAppBar extends Vue {
  auth = auth
  impersonation = impersonation
  colors = colors
  getId = getId
  navigation = getNavigationLinks()

  get topNavigationItems(): NavigationLink[] {
    return this.navigation.filter((item) => item.location === 'top')
  }

  get btnNavigationItems(): NavigationLink[] {
    return this.navigation.filter((item) => item.location === 'button')
  }

  get dropdownNavigationItems(): NavigationLink[] {
    return this.navigation.filter((item) => item.location === 'dropdown')
  }

  get userName(): string {
    return auth.getFullName
  }

  get isRoutePreauth(): boolean {
    return ['login', 'set-password'].includes(this.$route.name)
  }

  get showAppBarNavigation(): boolean {
    return !this.isRoutePreauth && auth.getIsTokenSet && !auth.getIsDriverOnly
  }

  openSidebar(): void {
    modules.menuSidebar.open()
  }

  isNavSelected(link: NavigationLink): boolean {
    return (
      link &&
      (this.$route.name === link.name ||
        (link.children || [])
          .map((c: NavigationLink) => c.name)
          .includes(this.$route.name))
    )
  }

  handleNavigationClick(item: NavigationLink): void {
    if (item.action) {
      item.action()
    }
  }

  mounted(): void {
    EventBus.$on('nav-btn:click', (name: string) => {
      alert(name)
    })
    this.navigation = getNavigationLinks(auth.isBusifyPayProcessor)
  }

  beforeDestroy(): void {
    EventBus.$off('nav-btn:click')
  }
}
