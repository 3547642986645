
import { Component, Prop, Vue } from 'vue-property-decorator'
import miniSidebar from '@/store/modules/miniSidebar'
import { MessageContactType } from '@/utils/enum'
import { TwilioContact } from '@/models/dto/Twilio'

@Component
export default class MessageContactTitle extends Vue {
  @Prop({ required: false, default: null }) contact: TwilioContact
  @Prop({ type: Boolean, default: false }) icon: boolean

  MessageContactType = MessageContactType

  get title(): string {
    return this.contact.name || `${this.contact.number || ''}`
  }

  get avatar(): string {
    return this.title.replace('<mark>', '')[0]
  }

  close(): void {
    miniSidebar.pop()
  }
}
