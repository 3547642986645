import { DriverPaymentPaystub } from '@/models/DriverPay'
import { MessageContactType } from '@/utils/enum'
import { v4 } from 'uuid'

// TODO: delete once messages are wired up
export const tempMessageHistory = [
  {
    sid: v4(),
    dateSent: 'Mon, 1 May 2023 17:44:46 +0000',
    to: 5058939482,
    from: 1239874567,
    direction: 'inbound',
    status: 'sent',
    body: 'Hi',
    unread: false,
  },
  {
    sid: v4(),
    dateSent: 'Mon, 1 May 2023 18:44:46 +0000',
    to: 5058939482,
    from: 1239874567,
    direction: 'outbound',
    status: 'received',
    body: 'ACME bus company, how can we help?',
    unread: false,
  },
  {
    sid: v4(),
    dateSent: 'Wed, 3 May 2023 14:44:46 +0000',
    to: 5058939482,
    from: 1239874567,
    direction: 'inbound',
    status: 'sent',
    body: 'Just contacting you to confirm that my bus will arrive on May 10th at 8:00am',
    unread: false,
  },
  {
    sid: v4(),
    dateSent: 'Thu, 4 May 2023 14:44:46 +0000',
    to: 5058939482,
    from: 1239874567,
    direction: 'outbound',
    status: 'received',
    body: "Yes that's correct, we have your reservation information here",
    unread: false,
  },
  {
    sid: v4(),
    dateSent: 'Thu, 4 May 2023 16:44:46 +0000',
    to: 5058939482,
    from: 1239874567,
    direction: 'inbound',
    status: 'received',
    body: 'And we will have time to eat lunch at our first stop?',
    unread: false,
  },
  {
    sid: v4(),
    dateSent: 'Thu, 4 May 2023 18:44:46 +0000',
    to: 5058939482,
    from: 1239874567,
    direction: 'outbound',
    status: 'received',
    body: "Yes, we've allowed 30 minutes for lunch",
    unread: false,
  },
  {
    sid: v4(),
    dateSent: 'Fri, 5 May 2023 10:44:46 +0000',
    to: 5058939482,
    from: 1239874567,
    direction: 'inbound',
    status: 'received',
    body: 'Thank you',
    unread: true,
  },
]

export const tempMessageContacts = [
  {
    id: 0,
    name: 'Herbert Richards',
    number: 5058293482,
    customerAccount: null,
    type: MessageContactType.EMPLOYEE,
    unreadCount: 2,
    lastMessage: 'Hi Chris, can you please confirm the contact info here?',
    lastMessageTime: '7:55 pm',
  },
  {
    id: 1,
    name: 'James Taylor',
    number: 5058293482,
    customerAccount: 'JDMT Buses',
    type: MessageContactType.CUSTOMER,
    unreadCount: 1,
    lastMessage:
      "Can you contact the client one more time, please? I'm not sure they have all my chickens, I need to be sure that the chickens were successfully retrieved after their escape",
    lastMessageTime: 'Yesterday',
  },
  {
    id: 2,
    name: 'busify',
    number: null,
    customerAccount: null,
    type: MessageContactType.BUSIFY,
    unreadCount: 0,
    lastMessage:
      'The driver arrived on the destination. Check if your client is aware of the pick up location.',
    lastMessageTime: 'Apr. 18',
  },
  {
    id: 3,
    name: null,
    number: 5058293482,
    customerAccount: null,
    type: MessageContactType.UNKNOWN,
    unreadCount: 0,
    lastMessage:
      "You: Hello from ACME! We're looking forward to our trip to Atlanta with you and just wanted to make sure you were aware of our chicken bonus. Let me know if you need more info!",
    lastMessageTime: 'Apr. 16',
  },
  {
    id: 4,
    name: null,
    number: 5058293482,
    customerAccount: null,
    type: MessageContactType.UNKNOWN,
    unreadCount: 0,
    lastMessage:
      "You: Hello from ACME! We're looking forward to our trip to Atlanta with you and just wanted to make sure you were aware of our chicken bonus. Let me know if you need more info!",
    lastMessageTime: 'Apr. 16',
  },
  {
    id: 5,
    name: null,
    number: 5058293482,
    customerAccount: null,
    type: MessageContactType.UNKNOWN,
    unreadCount: 0,
    lastMessage:
      "You: Hello from ACME! We're looking forward to our trip to Atlanta with you and just wanted to make sure you were aware of our chicken bonus. Let me know if you need more info!",
    lastMessageTime: 'Apr. 16',
  },
  {
    id: 6,
    name: null,
    number: 5058293482,
    customerAccount: null,
    type: MessageContactType.UNKNOWN,
    unreadCount: 0,
    lastMessage:
      "You: Hello from ACME! We're looking forward to our trip to Atlanta with you and just wanted to make sure you were aware of our chicken bonus. Let me know if you need more info!",
    lastMessageTime: 'Apr. 16',
  },
  {
    id: 7,
    name: null,
    number: 5058293482,
    customerAccount: null,
    type: MessageContactType.UNKNOWN,
    unreadCount: 0,
    lastMessage:
      "You: Hello from ACME! We're looking forward to our trip to Atlanta with you and just wanted to make sure you were aware of our chicken bonus. Let me know if you need more info!",
    lastMessageTime: 'Apr. 16',
  },
  {
    id: 8,
    name: null,
    number: 5058293482,
    customerAccount: null,
    type: MessageContactType.UNKNOWN,
    unreadCount: 0,
    lastMessage:
      "You: Hello from ACME! We're looking forward to our trip to Atlanta with you and just wanted to make sure you were aware of our chicken bonus. Let me know if you need more info!",
    lastMessageTime: 'Apr. 16',
  },
  {
    id: 9,
    name: null,
    number: 5058293482,
    customerAccount: null,
    type: MessageContactType.UNKNOWN,
    unreadCount: 0,
    lastMessage:
      "You: Hello from ACME! We're looking forward to our trip to Atlanta with you and just wanted to make sure you were aware of our chicken bonus. Let me know if you need more info!",
    lastMessageTime: 'Apr. 16',
  },
]
