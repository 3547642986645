
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class MessagesIcon extends Vue {
  @Prop({ type: Boolean, default: false }) isSelected: boolean

  get newMessageCount(): number {
    return 3
  }
}
