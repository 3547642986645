import { NavigationLink } from '@/models/NavigationLink'
import modules from '@/store/modules'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import MessagesIcon from '@/components/MessagesIcon.vue'
import TheMessagesPanel from '@/components/TheMessagesPanel.vue'
import app from '@/store/modules/app'
import sidebar from '@/store/modules/sidebar'

const hideIfNoRole = (role) => {
  return !modules.auth.getUserRoleNames.includes(role)
}

const operationsChildren = [
  {
    label: 'Tickets',
    href: 'tickets',
    name: 'tickets',
    location: '',
    params: {},
    permission: [],
  },
  {
    label: 'Drivers',
    href: 'drivers',
    name: 'drivers',
    location: '',
    params: {},
    permission: [],
  },
  {
    label: 'Vehicles',
    href: 'vehicles',
    name: 'vehicles',
    location: '',
    params: {},
    permission: [],
  },
]
let operationChildrenSet = false

export const getNavigationLinks = (
  showBusifyPay?: boolean
): NavigationLink[] => {
  if (!operationChildrenSet) {
    operationsChildren.unshift({
      label: 'Driver Pay',
      href: 'driver-pay',
      name: 'driver-pay',
      location: '',
      params: {},
      permission: [],
    })
    operationChildrenSet = true
  }

  return [
    {
      label: 'Quotes',
      href: 'quotes',
      name: 'quotes',
      location: 'top',
      params: {},
      permission: [],
      hidden: hideIfNoRole(ACCESS_SETTINGS_ROLES.QUOTES),
    },
    {
      label: 'Reservations',
      href: 'reservations',
      name: 'reservations',
      location: 'top',
      params: {},
      permission: [],
      hidden: hideIfNoRole(ACCESS_SETTINGS_ROLES.RESERVATIONS),
    },
    {
      label: 'Companies',
      href: 'admin.companies',
      name: 'admin.companies',
      location: 'top',
      params: {},
      permission: [],
      hidden:
        !modules.auth.getCanTransformCompany ||
        modules.impersonation.getHasTransformationSession,
    },
    {
      label: 'Users',
      href: 'admin.users',
      name: 'admin.users',
      location: 'top',
      params: {},
      permission: [],
      hidden:
        !modules.auth.getCanTransformCompany ||
        modules.impersonation.getHasTransformationSession,
    },
    {
      label: 'Dispatch',
      href: '',
      name: 'dispatch',
      location: 'top',
      params: {},
      permission: [],
      hidden:
        (modules.auth.getCanTransformCompany &&
          !modules.impersonation.getHasTransformationSession) ||
        hideIfNoRole(ACCESS_SETTINGS_ROLES.DISPATCH),
      children: [
        {
          label: 'Calendar',
          href: 'dispatch',
          name: 'dispatch',
          location: '',
          params: {},
          permission: [],
        },
        {
          label: 'Grid',
          href: 'assignments',
          name: 'assignments',
          location: '',
          params: {},
          permission: [],
        },
      ],
    },
    {
      label: 'Contacts',
      href: '',
      name: 'contacts',
      location: 'top',
      hideOnBreakpoint: 'mdAndDown',
      params: {},
      permission: [],
      hidden:
        (modules.auth.getCanTransformCompany &&
          !modules.impersonation.getHasTransformationSession) ||
        hideIfNoRole(ACCESS_SETTINGS_ROLES.CONTACTS),
      children: [
        {
          label: 'Contacts',
          href: 'contacts',
          name: 'contacts',
          location: '',
          params: {},
          permission: [],
        },
        {
          label: 'Companies',
          href: 'companies',
          name: 'companies',
          location: '',
          params: {},
          permission: [],
        },
      ],
    },
    {
      label: 'Operations',
      href: '',
      name: 'operations',
      location: 'top',
      hideOnBreakpoint: 'mdAndDown',
      params: {},
      permission: [],
      hidden:
        (modules.auth.getCanTransformCompany &&
          !modules.impersonation.getHasTransformationSession) ||
        hideIfNoRole(ACCESS_SETTINGS_ROLES.OPERATIONS),
      children: operationsChildren,
    },
    {
      label: 'More',
      href: '',
      name: 'more',
      location: 'top',
      hideOnBreakpoint: 'lgAndUp',
      params: {},
      permission: [],
      hidden:
        modules.auth.getCanTransformCompany &&
        !modules.impersonation.getHasTransformationSession,
      children: [
        {
          label: 'Contacts',
          href: 'contacts',
          name: 'contacts',
          location: '',
          params: {},
          permission: [],
        },
        {
          label: 'Companies',
          href: 'companies',
          name: 'companies',
          location: '',
          params: {},
          permission: [],
        },
        {
          label: 'Tickets',
          href: 'tickets',
          name: 'tickets',
          location: '',
          params: {},
          permission: [],
        },
        {
          label: 'Drivers',
          href: 'drivers',
          name: 'drivers',
          location: '',
          params: {},
          permission: [],
        },
        {
          label: 'Vehicles',
          href: 'vehicles',
          name: 'vehicles',
          location: '',
          params: {},
          permission: [],
        },
        {
          label: 'Driver Pay',
          href: 'driver-pay',
          name: 'driver-pay',
          location: '',
          params: {},
          permission: [],
        },
      ],
    },
    // To be added back in post-launch
    // {
    //   label: 'Search',
    //   href: '',
    //   name: 'search',
    //   location: 'button',
    //   params: {},
    //   permission: [],
    //   icon: 'search',
    //   action: () => EventBus.$emit('nav-btn:click', 'search'),
    // },
    {
      label: 'Payments Dashboard',
      action: () => window.open('https://busify.payments-dashboard.com/'),
      name: 'payments',
      location: 'button',
      params: {},
      permission: [],
      hidden: !showBusifyPay,
      icon: 'credit_card',
    },
    {
      label: 'Availability',
      href: 'availability',
      name: 'availability',
      location: 'button',
      params: {},
      permission: [],
      icon: 'event_available',
      isNew: true,
    },
    {
      label: 'Settings',
      href: 'settings',
      name: 'settings',
      location: 'button',
      params: {},
      permission: [],
      icon: 'settings',
    },
    {
      label: 'Knowledge Center',
      name: 'knowledge-center',
      location: 'button',
      action: () => window.open('https://knowledge.busify.com/_hcms/mem/login'),
      params: {},
      permission: [],
      icon: 'knowledge_center',
    },
    ...(app.isSystemParameterTrue('isMessagingEnabled')
      ? [
          {
            label: 'Messages',
            name: 'messages',
            location: 'button',
            action: () =>
              sidebar.toggle({
                component: TheMessagesPanel,
                width: 432,
              }),
            params: {},
            permission: [],
            component: MessagesIcon,
          },
        ]
      : []),
    {
      label: 'Change Password',
      location: 'dropdown',
      action: () => modules.auth.openChangePassword(),
      params: {},
      permission: [],
    },
    {
      label: 'Logout',
      location: 'dropdown',
      action: () => modules.auth.logout(),
      params: {},
      permission: [],
    },
    {
      label: 'Login as Operator',
      location: 'dropdown',
      action: () => modules.impersonation.open(),
      params: {},
      permission: [],
      hidden:
        !modules.auth.getCanTransformCompany ||
        modules.impersonation.getHasTransformationSession,
    },

    {
      label: 'Back To Admin',
      location: 'dropdown',
      action: () => modules.impersonation.stopTransformationSession(),
      params: {},
      permission: [],
      hidden: !modules.impersonation.getHasTransformationSession,
    },
  ]
}
