export interface TwilioContact {
  id: number
  name: string
  number: number | string
  customerAccount: string
  type: number
  unreadCount: number
  lastMessage: string
  lastMessageTime: string
}

export interface TwilioMessage {
  sid: string
  body: string
  dateSent: string
  direction: string
  from: number
  to: number
  status: string
  error_message?: string
  unread: boolean
  numMedia?: number
  numSegments?: number
  subresourceUris?: { media: string; feedback: string }
}

// eslint-disable-next-line no-redeclare
export class TwilioMessage implements TwilioMessage {
  constructor(message?: Partial<TwilioMessage>) {
    Object.assign(this, message)
  }
}
