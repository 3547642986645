
import { Component, Prop, Vue } from 'vue-property-decorator'
import miniSidebar from '@/store/modules/miniSidebar'
import MessageContactTitle from './MessageContactTitle.vue'
import { phoneFormatFilter } from '@/utils/string'
import { TwilioContact } from '@/models/dto/Twilio'

@Component({
  components: {
    MessageContactTitle,
  },
})
export default class MessageContactSearch extends Vue {
  @Prop({ required: true, default: () => [] }) contacts: TwilioContact[]

  phoneFormatFilter = phoneFormatFilter

  search = ''
  hover = null

  get filteredContacts(): TwilioContact[] {
    if (!this.search) {
      return []
    }
    return this.contacts.filter((c) => {
      const query = this.search.toLowerCase()
      return (
        c.name?.toLowerCase().includes(query) ||
        c.customerAccount?.toLowerCase().includes(query) ||
        c.number?.toString().includes(query)
      )
    })
  }

  close(): void {
    miniSidebar.pop()
  }
}
