
import { Vue, Component, Prop } from 'vue-property-decorator'
import { UserDetail, UserPhoto } from '@/models/dto'
import { staticResource } from '@/utils/env'
import auth from '@/store/modules/auth'
import colors from '@/scss/_colors-export.scss'

@Component({})
export default class CUAvatar extends Vue {
  @Prop({ required: true }) readonly userDetail!: UserDetail

  get userInitial(): string {
    return (this.userDetail.firstName || '').charAt(0)
  }

  get photoAlt(): string {
    return `Photo of ${this.userDetail.firstName} ${this.userDetail.lastName}`
  }

  get profilePicture(): string {
    const photo: UserPhoto | undefined = this.userDetail.userPhotoDTOs?.find(
      (p: UserPhoto) => p.primaryImage && p.active
    )
    return staticResource(photo?.imagePath)
  }

  get backgroundColor(): string {
    return auth.getCompany?.primaryColor || colors['bg-gray-5']
  }
}
