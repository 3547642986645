
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import miniSidebar from '@/store/modules/miniSidebar'
import MessageContactTitle from './MessageContactTitle.vue'
import { phoneFormatFilter } from '@/utils/string'
import MessageContactSearch from './MessageContactSearch.vue'
import { TwilioContact, TwilioMessage } from '@/models/dto/Twilio'
import { tempMessageHistory } from '@/data/temp'
import colors from '@/scss/_colors-export.scss'
import MessageMessagesPanel from './MessageMessagesPanel.vue'

const SIDEBAR_DEFAULT_HEIGHT = 380
const SIDEBAR_WITH_CONTENT_HEIGHT = 600

@Component({
  components: {
    MessageContactTitle,
    MessageContactSearch,
    MessageMessagesPanel,
  },
})
export default class MessageContactPanel extends Vue {
  @Prop({ required: true, default: () => [] }) contacts: TwilioContact[]
  @Prop({ required: false, default: null }) inputContact: TwilioContact

  phoneFormatFilter = phoneFormatFilter
  colors = colors

  loading = false
  contact: TwilioContact = null
  messageHistory: TwilioMessage[] = []
  newMessage = ''
  attachedFile = null

  @Watch('inputContact', { immediate: true })
  handleInputContact(): void {
    if (this.inputContact) {
      this.loadContactData(this.inputContact)
    } else {
      this.contact = null
    }
  }

  @Watch('contact', { immediate: true })
  handleUpdateContact(): void {
    miniSidebar.setHeight(
      this.contact ? SIDEBAR_WITH_CONTENT_HEIGHT : SIDEBAR_DEFAULT_HEIGHT
    )
  }

  get imageUrl(): string {
    return this.attachedFile ? URL.createObjectURL(this.attachedFile) : ''
  }

  handleSelectContact(contact: TwilioContact): void {
    this.loadContactData(contact)
  }

  async handleSendMessage(): Promise<void> {
    if (!this.newMessage && !this.attachedFile) {
      return
    }

    this.loading = true
    await new Promise((r) => setTimeout(r, 1000)) // TODO: save new message

    this.newMessage = ''
    this.attachedFile = null
    this.loading = false
  }

  handleUploadFile(event: Event): void {
    const target = event.target as HTMLInputElement
    this.attachedFile = target.files[0]
  }

  close(): void {
    miniSidebar.pop()
  }

  async loadContactData(contact: TwilioContact): Promise<void> {
    this.loading = true
    this.messageHistory = []
    this.contact = {
      ...contact,
      number: phoneFormatFilter(contact.number?.toString() || ''),
    }

    await new Promise((r) => setTimeout(r, 1000)) // TODO: fetch message history data
    this.messageHistory = tempMessageHistory

    this.loading = false
  }
}
