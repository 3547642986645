var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUScroll',{staticClass:"flex-grow-1",style:({ flexBasis: '100%' }),attrs:{"no-fade":"","simple":"","scroll":_vm.scroll}},[_c('div',{ref:"message-area-wrapper",staticClass:"padding-l-5 padding-r-2"},_vm._l((_vm.displayMessages),function(message,idx){return _c('div',{key:`message-history-${message.sid}-${idx}`,class:{
        'position-sticky top-0 background-white': !message.direction,
      }},[(!message.direction)?_c('div',{staticClass:"d-flex align-center justify-space-between margin-y-4"},[_c('v-divider'),_c('p',{staticClass:"font-bold font-12 padding-x-3"},[_vm._v(" "+_vm._s(message.dateSent)+" ")]),_c('v-divider')],1):_c('div',{staticClass:"d-flex flex-column margin-b-5",class:{
          'align-end': message.direction === 'outbound',
          'align-start': message.direction === 'inbound',
        }},[_c('div',{staticClass:"d-flex align-top"},[(message.direction === 'inbound')?_c('MessageContactTitle',{staticClass:"margin-t-1",attrs:{"icon":"","contact":_vm.contact}}):_vm._e(),_c('div',[_c('div',{staticClass:"padding-a-4 border-1 border-solid border-radius-10 margin-l-1",class:{
                'border-primary-orange-4 background-primary-orange-3':
                  message.direction === 'outbound',
                'border-gray-border-light': message.direction === 'inbound',
              }},[_c('p',{staticClass:"text-text-gray-4"},[_vm._v(_vm._s(message.body))])]),_c('div',{staticClass:"d-flex align-center margin-t-1 margin-x-3",class:{
                'justify-end': message.direction === 'outbound',
                'justify-start': message.direction === 'inbound',
              }},[(message.unread && message.direction === 'inbound')?_c('div',{staticClass:"h-10 w-10 rounded-pill background-primary margin-r-1"}):_vm._e(),_c('p',{staticClass:"font-12 text-bg-gray-6"},[_vm._v(_vm._s(message.dateSent))])])])],1)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }